import React from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import "./../App.css";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Terms() {

  React.useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/terms", title: "Terms Page" });
  }, [])

  return (
    <div className="App">
    <header className="App-header">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <center>
              <br/>
              <h1 className="brand"></h1>
              <h2>Terms and Conditions</h2>
            </center>
            <div style={{ textAlign: 'left', padding: '20px', color: '#fff' }}>
              <h3>Introduction</h3>
              <p>
                Welcome to Robotics.dev. These Terms and Conditions govern your use of our platform and
                services. By accessing or using our platform, you agree to comply with these terms.
              </p>

              <h3>Use of the Platform</h3>
              <p>
                Users must adhere to the following guidelines when using Robotics.dev:
              </p>
              <ul>
                <li>Use the platform in compliance with applicable laws and regulations.</li>
                <li>Do not engage in unauthorized access or misuse of our services.</li>
                <li>Respect the intellectual property rights of Robotics.dev and others.</li>
              </ul>

              <h3>Account Responsibilities</h3>
              <p>
                If you create an account on Robotics.dev, you are responsible for maintaining the
                confidentiality of your credentials. Notify us immediately if you suspect unauthorized
                access or usage.
              </p>

              <h3>Intellectual Property</h3>
              <p>
                All content on Robotics.dev, including text, graphics, logos, and software, is the
                property of Robotics.dev or its licensors. Unauthorized use or reproduction is
                prohibited.
              </p>

              <h3>Limitation of Liability</h3>
              <p>
                Robotics.dev is not liable for any direct, indirect, or consequential damages arising
                from the use of our platform. Use the services at your own risk.
              </p>

              <h3>Termination</h3>
              <p>
                We reserve the right to suspend or terminate access to the platform if users violate
                these Terms and Conditions.
              </p>

              <h3>Changes to the Terms</h3>
              <p>
                Robotics.dev reserves the right to modify these Terms and Conditions. Users will be
                notified of significant changes through the platform.
              </p>

              <h3>Governing Law</h3>
              <p>
                These Terms and Conditions are governed by the laws of Maricopa County, Arizona. Disputes
                will be resolved in the courts of Maricopa County, Arizona.
              </p>

              <h3>Contact Us</h3>
              <p>
                For questions or concerns about these Terms and Conditions, please contact us at:
                <br />
                <a href="mailto:terms@robotics.dev">terms@robotics.dev</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
      <footer>
          <p>&copy; 2025. All rights reserved.</p>
          <p><a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms & Conditions</a></p>
      </footer>
    </div>
  )
}

export default Terms;
