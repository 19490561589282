import React from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import "./../App.css";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Privacy() {

  React.useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/privacy", title: "Privacy Page" });
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <center>
                <br/>
                <h1 className="brand"></h1>
                <h2>Privacy Policy</h2>
              </center>
              <div style={{ textAlign: 'left', padding: '20px', color: '#fff' }}>
                <h3>Introduction</h3>
                <p>
                  At Robotics.dev, we prioritize the privacy of our users. This Privacy Policy outlines
                  how we collect, use, and protect your personal information when you use our platform.
                </p>

                <h3>Information We Collect</h3>
                <ul>
                  <li>Personal identification information (Name, email address, etc.)</li>
                  <li>Usage data, such as your interactions with the platform</li>
                  <li>Technical data, including IP address and device information</li>
                </ul>

                <h3>How We Use Your Information</h3>
                <p>
                  Robotics.dev uses your information to:
                </p>
                <ul>
                  <li>Provide and improve our services</li>
                  <li>Communicate updates, announcements, and support</li>
                  <li>Ensure security and compliance with legal obligations</li>
                </ul>

                <h3>Data Protection</h3>
                <p>
                  We implement strict security measures to protect your personal data from unauthorized
                  access, alteration, or disclosure.
                </p>

                <h3>Your Rights</h3>
                <p>
                  As a user, you have the right to:
                </p>
                <ul>
                  <li>Access, update, or delete your personal information</li>
                  <li>Opt out of certain communications</li>
                  <li>File a complaint regarding data usage</li>
                </ul>

                <h3>Changes to This Policy</h3>
                <p>
                  Robotics.dev reserves the right to update this Privacy Policy as needed. We will notify
                  users of significant changes through our platform.
                </p>

                <h3>Contact Us</h3>
                <p>
                  If you have any questions about this Privacy Policy, please contact us at:
                  <br />
                  <a href="mailto:privacy@robotics.dev">privacy@robotics.dev</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <footer>
          <p>&copy; 2025. All rights reserved.</p>
          <p><a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms & Conditions</a></p>
      </footer>
    </div>
  )
}

export default Privacy;
