// import React, { useEffect, useState, useRef } from "react";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import "./../App.css";
import robot from './../images/android-chrome-192x192.png';
// import Blockly from 'blockly';
// import * as Blockly from 'blockly';
import { BlocklyWorkspace } from 'react-blockly';
import "./../components/customBlocks";
import tools from "./../components/toolbox";
// import 'blockly/blocks';
import { javascriptGenerator } from 'blockly/javascript';
import {ContinuousToolbox} from "@blockly/continuous-toolbox";
import Swal from 'sweetalert2'
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import io from 'socket.io-client';

import { LiveProvider, LiveEditor } from "react-live";
import { themes } from "prism-react-renderer";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

const { REACT_APP_NODE_ENV, REACT_APP_IP_ADDRESS } = process.env;
var socket

const toolboxCategories = tools;
// const worker = new Worker('worker.js');

const preCode = `
function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
};

const detect_objects_on_image = async (buf) => {
  const [input,img_width,img_height] = await prepare_input(buf);
  const output = await run_model(input);
  return process_output(output);
}
const prepare_input = async (buf) => {
    return new Promise(resolve => {
        const img = new Image();
        img.src = 'data:image/jpeg;base64,' + buf;
        img.onload = () => {
            const [img_width,img_height] = [img.width, img.height]
            const canvas = document.createElement("canvas");
            canvas.width = 640;
            canvas.height = 640;
            const context = canvas.getContext("2d");
            context.drawImage(img,0,0,640,640);
            const imgData = context.getImageData(0,0,640,640);
            const pixels = imgData.data;
            const red = [], green = [], blue = [];
            for (let index=0; index<pixels.length; index+=4) {
                red.push(pixels[index]/255.0);
                green.push(pixels[index+1]/255.0);
                blue.push(pixels[index+2]/255.0);
            }
            const input = [...red, ...green, ...blue];
            resolve([input, img_width, img_height])
        }
    })
}
const run_model = async (input) => {
    const model = await ort.InferenceSession.create("/yolov8m.onnx");
    input = new ort.Tensor(Float32Array.from(input),[1, 3, 640, 640]);
    const outputs = await model.run({images:input});
    return outputs["output0"].data;
}
const process_output = (output, img_width, img_height) => {
    let boxes = [];
    for (let index=0;index<8400;index++) {
        const [class_id,prob] = [...Array(80).keys()]
            .map(col => [col, output[8400*(col+4)+index]])
            .reduce((accum, item) => item[1]>accum[1] ? item : accum,[0,0]);
        if (prob < 0.5) {
            continue;
        }
        const label = yolo_classes[class_id];
        if (!boxes.includes(label)){
          boxes.push(label);
        }
    }
    return boxes;
};
const yolo_classes = [
    'person', 'bicycle', 'car', 'motorcycle', 'airplane', 'bus', 'train', 'truck', 'boat',
    'traffic light', 'fire hydrant', 'stop sign', 'parking meter', 'bench', 'bird', 'cat', 'dog', 'horse',
    'sheep', 'cow', 'elephant', 'bear', 'zebra', 'giraffe', 'backpack', 'umbrella', 'handbag', 'tie', 'suitcase',
    'frisbee', 'skis', 'snowboard', 'sports ball', 'kite', 'baseball bat', 'baseball glove', 'skateboard',
    'surfboard', 'tennis racket', 'bottle', 'wine glass', 'cup', 'fork', 'knife', 'spoon', 'bowl', 'banana', 'apple',
    'sandwich', 'orange', 'broccoli', 'carrot', 'hot dog', 'pizza', 'donut', 'cake', 'chair', 'couch', 'potted plant',
    'bed', 'dining table', 'toilet', 'tv', 'laptop', 'mouse', 'remote', 'keyboard', 'cell phone', 'microwave', 'oven',
    'toaster', 'sink', 'refrigerator', 'book', 'clock', 'vase', 'scissors', 'teddy bear', 'hair drier', 'toothbrush'
];
`

function BlocklyDesigner() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [token, setToken] = useState('');
  const [xml, setXml] = useState(null);
  const [javascriptCode, setJavascriptCode] = useState();
  const [automationId, setAutomationId] = useState(null);
  const [name, setName] = useState(null);
  const location = useLocation();
  const [robot, setRobot] = useState('');
  const [robots, setRobots] = useState([]);
  const [rgbStream, setRgbStream] = useState('');
  const [editor, setEditor] = useState(false);
  // const workerRef = useRef(null);

  async function getRobots(userid){
    const q = query(collection(db, "robots"), where("uid", "==", userid));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size > 0){
      let robotList = [];
      querySnapshot.forEach((doc) => {
        var robotX = doc.data();
        robotX.id = doc.id;
        robotList.push(robotX);

        socket.on(`${robotX.id}/camera2d`, (message) => {
          // console.log(message);
          // if(message.id === document.getElementById("mrRobot").value){
            setRgbStream("data:image/png;base64, " + message.data.data);
          // } else {
          //   setRgbStream("data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=")
          // }
        });
      });
      setRobots(robotList);
      setRobot(robotList[0].id)
    } else {
      setRobots([]);
    }
  }

  const getUserToken = async (uid) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists) {
      var user = docSnap.data();
      setToken(user.apiToken)
    }
  }

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/designer", title: "Designer Page" });

      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUid(user.uid);
          // getRobots(user.uid);
          getUserToken(user.uid);
        } else {
          console.log("user is logged out")
          navigate("/")
        }
      });


      // connect to socket.io
      // var socket
      console.log(REACT_APP_NODE_ENV, REACT_APP_IP_ADDRESS);
      if (REACT_APP_NODE_ENV === 'development') {
        if(REACT_APP_IP_ADDRESS){
          socket = io(`http://${REACT_APP_IP_ADDRESS}:3001`);
        } else {
          socket = io('http://localhost:3001');
        }
        // console.log('development');
      }
      if (process.env.NODE_ENV === 'production') {
        socket = io('https://robotics.dev');
        // console.log('production');
      }

      socket.on("connect", () => {
        console.log("socketId:", socket.id); // x8WIv7-mJelg7on_ALbx
      });

      if(location.state){
        if(location.state.id === "new"){
          setXml('<xml xmlns="https://developers.google.com/blockly/xml"></xml>');
        } else {
          setAutomationId(location.state.id);
          setName(location.state.name);
          setXml(location.state.xml);
        }
      } else {
        const storedXml = localStorage.getItem('blocklyXml');
        if (storedXml) {
          setXml(storedXml);
        } else {
          setXml('<xml xmlns="https://developers.google.com/blockly/xml"></xml>');
        }
        const storedJs = localStorage.getItem('blocklyJs');
        if (storedJs) {
          setJavascriptCode(storedJs);
        }
      }

      // // Create the worker instance
      // // workerRef.current = new Worker(new URL('./worker.js', import.meta.url));
      // workerRef.current = new Worker('worker.js');
      //
      // // Handle messages from the worker
      // workerRef.current.onmessage = (event) => {
      //   // setResult(event.data);
      //   console.log(event.data);
      // };
      //
      // // Terminate the worker when the component unmounts
      // return () => {
      //   workerRef.current.terminate();
      // };

  }, [])

  function workspaceDidChange(workspace) {
    const code = javascriptGenerator.workspaceToCode(workspace);
    setJavascriptCode(code);
    localStorage.setItem('blocklyJs', code); // Store in local storage
  }

  function runCode(){
    var codeToRun = preCode + javascriptCode
    codeToRun = codeToRun.replace(/APITOKEN/g, token);
    console.log(codeToRun);
    try {
      // eval(javascriptCode);
      // (async () => { eval(javascriptCode) })();
      eval(codeToRun);
    } catch (e) {
      alert(e);
    }

    // if (workerRef.current) {
    //   workerRef.current.postMessage(code); // Send data to the worker
    // }

  }

  const toggleEditor = () => {
    setEditor(!editor);
  }

  async function saveCode(){
    var fileName;
    if(name){
      fileName = name;
    } else {
      var promptName = await Swal.fire({
        title: 'Enter automation name',
        input: 'text',
        customClass: {
          validationMessage: 'my-validation-message',
        },
        preConfirm: (value) => {
          if (!value) {
            Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Automation name is required')
          } else {
            setName(value);
          }
        },
      })
      fileName = promptName.value
    }

    var autoId;
    if (automationId){
      autoId = automationId;
    } else {
      autoId = uuidv4();
      setAutomationId(autoId);
    }
    // console.log("AUTO", autoId, fileName);
    setDoc(doc(db, "automations", autoId), {
      uid: uid,
      name: fileName,
      xml: xml
    }, { merge: true })
    .then(
      // getAutomations(uid)
    )

  }

  const handleXmlChange = (newXml) => {
    setXml(newXml); // Update state with new XML
    localStorage.setItem('blocklyXml', newXml); // Store in local storage
  };

  return (
    <div className="App">
      <div className="d-flex align-items-end justify-content-end">
        <button type="button" className="btn btn-secondary" onClick={() => toggleEditor()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-card-text" viewBox="0 0 16 16">
            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
            <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8m0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"/>
          </svg>
          &nbsp; {editor ? "Designer" : "Editor"}
        </button>
        <button type="button" className="btn btn-primary" onClick={() => saveCode()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-save" viewBox="0 0 16 16">
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"></path>
          </svg>
          &nbsp; SAVE
        </button>
        <button type="button" className="btn btn-success run" onClick={() => runCode()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16">
            <path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z"></path>
          </svg>
          RUN
        </button>
        <button type="button" className="btn btn-danger run">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-stop" viewBox="0 0 16 16">
            <path d="M3.5 5A1.5 1.5 0 0 1 5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11zM5 4.5a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5z"></path>
          </svg>
          STOP
        </button>
      </div>
    {editor ?

      <div className="col-sm-12 fill-height">
        <LiveProvider code={javascriptCode} language='javascript' theme={themes.dracula}>
          <LiveEditor onChange={newCode => { setJavascriptCode(newCode) }}/>
        </LiveProvider>
        <br/><br/>
      </div>

    :

    xml != null ?
      <>
      <BlocklyWorkspace
        toolboxConfiguration={toolboxCategories}
        plugins={ContinuousToolbox}
        toolboxCategories={toolboxCategories}
        initialXml={xml}
        className="fill-height"
        workspaceConfiguration={{
          toolbox: {
            kind: "categoryToolbox",
          },
          grid: {
            spacing: 20,
            length: 3,
            colour: "#ccc",
            snap: true,
          },
          zoom: {
            controls: true,
            wheel: true,
            startScale: 1.0,
            maxScale: 3,
            minScale: 0.3,
            scaleSpeed: 1.2,
            pinch: true
          },
          trashcan: true
        }}
        onWorkspaceChange={workspaceDidChange}
        onXmlChange={handleXmlChange}
      />
      {rgbStream ? <img src={rgbStream} width="424" height="240"/>: ''}
      <canvas></canvas>
      </>
      : "" }
    }
    </div>
  )
}

export default BlocklyDesigner;
