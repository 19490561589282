import React, { useEffect, useState }  from "react";
import { NavLink, useNavigate, Link } from 'react-router-dom'

import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import "./../App.css";
import { CopyBlock, CodeBlock, dracula } from "react-code-blocks";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Robots() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [robot, setRobot] = useState('');
  const [robots, setRobots] = useState([]);
  const [robotName, setRobotName] = useState('');
  const [addRobot, setAddRobot] = useState(false);
  const [newRobot, setNewRobot] = useState(false);
  const [postRos, setPostRos] = useState('ROS2');
  const [postCamera, setPostCamera] = useState('none');
  const [postType, setPostType] = useState('AMR');
  const [postWheels, setPostWheels] = useState(undefined);
  const [postWheelDiameter, setPostWheelDiameter] = useState(undefined);
  const [postWheelWidth, setPostWheelWidth] = useState(undefined);
  const [postWheelBetween, setPostWheelBetween] = useState(undefined);
  const [postCmdvel, setPostCmdvel] = useState(undefined);
  const [postRosTopics, setPostRosTopics] = useState([]);

  async function getRobots(userid){
    setAddRobot(false)
    const q = query(collection(db, "robots"), where("uid", "==", userid));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size > 0){
      let robotList = [];
      querySnapshot.forEach((doc) => {
        var robotX = doc.data();
        robotX.id = doc.id;
        robotList.push(robotX);
      });
      setRobots(robotList);
    } else {
      setRobots([]);
    }
  }

  const handleAddRobot = () => {
    setRobot('');
    setRobotName('')
    setPostType('AMR');
    setPostRos('ROS2');
    setPostCamera('none');
    setPostWheels(undefined);
    setPostWheelDiameter(undefined);
    setPostWheelWidth(undefined);
    setPostWheelBetween(undefined);
    setPostCmdvel(undefined);
    setPostRosTopics([]);
    setAddRobot(!addRobot)
    setNewRobot(true)
  }

  const updateRobot = () => {
    var robotid
    if (robot === ""){
      robotid = uuidv4();
    } else {
      robotid = robot;
    }
    setDoc(doc(db, "robots", robotid), {
      uid: uid,
      name: robotName ? robotName : "",
      type: postType,
      ros: postRos,
      camera: postCamera,
      wheelCount: postWheels ? parseInt(postWheels) : 0,
      wheelDiameter: postWheelDiameter ? parseInt(postWheelDiameter) : 0,
      wheelWidth: postWheelWidth ? parseInt(postWheelWidth) : 0,
      wheelBetween: postWheelBetween ? parseInt(postWheelBetween) : 0,
      cmdVel: postCmdvel ? postCmdvel : "",
      rosTopics: postRosTopics ? postRosTopics : []
    }, { merge: true })
    .then(
      getRobots(uid)
    )
  }

  const addRosTopic = async () => {
    // ROS_TOPICS=[{"type":"nav_msgs/msg/Odometry","topic":"/odom"}]
    var type = document.getElementById("msgType").value;
    var topic = document.getElementById("msgTopic").value;
    setPostRosTopics([...postRosTopics, {type: type, topic: topic }]);
  }
  const addRealSenseRosTopic = async () => {
    setPostRosTopics([...postRosTopics,
      { type: "sensor_msgs/Image", topic: "/camera/color/image_raw" },
      { type: "sensor_msgs/CameraInfo", topic: "/camera/color/camera_info" },
      { type: "sensor_msgs/Image", topic: "/camera/depth/image_rect_raw" },
      { type: "sensor_msgs/CameraInfo", topic: "/camera/depth/camera_info" },
      { type: "sensor_msgs/Image", topic: "/camera/infra1/image_rect_raw" },
      { type: "sensor_msgs/CameraInfo", topic: "/camera/infra1/camera_info" },
      { type: "sensor_msgs/Image", topic: "/camera/infra2/image_rect_raw" },
      { type: "sensor_msgs/CameraInfo", topic: "/camera/infra2/camera_info" },
      { type: "sensor_msgs/PointCloud2", topic: "/camera/depth/color/points" },
      { type: "sensor_msgs/Imu", topic: "/camera/gyro/sample" },
      { type: "sensor_msgs/Imu", topic: "/camera/accel/sample" }
    ]);
  }
  const deleteRosTopic = async (topic) => {
    // ROS_TOPICS=[{"type":"nav_msgs/msg/Odometry","topic":"/odom"}]
    // setPostRosTopics([...postRosTopics, {type: type, topic: topic }]);
    // Create a new array without the item to be removed
    const updatedTopics = postRosTopics.filter(item => item.topic !== topic);
    setPostRosTopics(updatedTopics);
  }

  const deleteRobot = async (bot) => {
    // console.log(bot);
    await deleteDoc(doc(db, "robots", bot));
    getRobots(uid)
  }

  const handleRobotId = (bot) => {
    // console.log(bot.target.value);
    // setRobot(bot.target.value);
    for (var i = 0, l = robots.length; i < l; i++) {
      if(robots[i].id === bot){
        // console.log(robots[i]);
        setRobot(robots[i].id);
        setRobotName(robots[i].name)
        setPostType(robots[i].type);
        setPostRos(robots[i].ros);
        setPostCamera(robots[i].camera);
        setPostWheels(robots[i].wheelCount);
        setPostWheelDiameter(robots[i].wheelDiameter);
        setPostWheelWidth(robots[i].wheelWidth);
        setPostWheelBetween(robots[i].wheelBetween);
        setPostCmdvel(robots[i].cmdVel);
        if(robots[i].rosTopics){
            setPostRosTopics(robots[i].rosTopics);
        } else {
          setPostRosTopics([])
        }

      }
    }
    setAddRobot(!addRobot);
    setNewRobot(false);
  };

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/robots", title: "Robots Page" });

      onAuthStateChanged(auth, (user) => {
          if (user) {
            setUid(user.uid);
            getRobots(user.uid);
          } else {
            console.log("user is logged out")
            navigate("/")
          }
        });

  }, [])

  return (
    <div className="App">
      <header className="App-header">

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                {robots.length === 0 ? "No robots created yet..." :
                <table className="table table-striped table-dark">
                <thead>
                  <tr key={Math.random()}>
                    <th scope="col" key={Math.random()}></th>
                    <th scope="col" key={Math.random()}>Name</th>
                    <th scope="col" key={Math.random()}>Robot Id</th>
                    <th scope="col" key={Math.random()}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {robots.map((robot, index) => (
                    <tr key={index}>
                      <td key={Math.random()}><img alt="robot" src={`https://robohash.org/${robot.id}?size=40x40`}/></td>
                      <td key={Math.random()}>{robot.name}</td>
                      <td key={Math.random()}>
                      <CopyBlock
                        text={`${robot.id}`}
                        language={"bash"}
                        showLineNumbers={false}
                        wrapLongLines
                        theme={dracula}
                      />
                      </td>
                      <td key={Math.random()}>
                        <button className="btn btn-outline-light btn-lg" onClick={() => handleRobotId(robot.id)}>
                          Edit
                        </button>&nbsp;
                        <button className="btn btn-outline-danger btn-lg" onClick={() => deleteRobot(robot.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                </table>
                }
              </div>
              <div className="d-flex justify-content-center">
                <button className="btn btn-outline-light btn-lg" onClick={handleAddRobot}>
                  Add Robot
                </button>
              </div>
              {addRobot ?
                <>
                <br/>
                <input className="form-control form-control-lg" type="text" placeholder="Robot Name" aria-label="robot name" onChange={e => setRobotName(e.target.value)} value={robotName}/>
                <input className="form-control form-control-lg" type="text" placeholder="Robot Id" aria-label="robot id" onChange={e => setRobot(e.target.value)} value={robot} disabled={!newRobot}/>

                <p><br/>
                Type of Robot: &nbsp;
                <select id="type" onChange={e => setPostType(e.target.value)} value={postType}>
                   <option value="AMR">AMR</option>
                   <option value="AGV">AGV</option>
                   <option value="Stationary">Stationary</option>
                   <option value="Drone">Drone</option>
                </select>
                </p>
                <p>
                RealSense Camera: &nbsp;
                <select id="ros" onChange={e => setPostCamera(e.target.value)} value={postCamera}>
                   <option value="none">None</option>
                   <option value="D405">D405</option>
                   <option value="D415">D415</option>
                   <option value="D421">D421</option>
                   <option value="D435">D435</option>
                   <option value="D435i">D435i</option>
                   <option value="D455">D455</option>
                   <option value="D456">D456</option>
                   <option value="D457">D457</option>
                </select>
                </p>
               
                Odometry Parameters: &nbsp;
                <input className="form-control form-control-lg" type="text" placeholder="Wheel Count" onChange={e => setPostWheels(e.target.value)} value={postWheels}/>
                <input className="form-control form-control-lg" type="text" placeholder="Wheel Diameter (mm)" onChange={e => setPostWheelDiameter(e.target.value)} value={postWheelDiameter}/>
                <input className="form-control form-control-lg" type="text" placeholder="Wheel Width (mm)" onChange={e => setPostWheelWidth(e.target.value)} value={postWheelWidth}/>
                <input className="form-control form-control-lg" type="text" placeholder="Distance Between Wheels (mm)" onChange={e => setPostWheelBetween(e.target.value)} value={postWheelBetween}/>
                <br/>
                <p>
                  ROS2 Topics: &nbsp;<br/>
                  Override motor controller topic: &nbsp;
                  <input className="form-control form-control-lg" type="text" placeholder={`/robot${robot.replace(/-/g, "")}/cmd_vel`} id="motorTopic" onChange={e => setPostCmdvel(e.target.value)} value={postCmdvel}/>
                </p>
                <p>
                  Manage ROS2 topics streaming to your RDK: &nbsp;<br/>
                  <Link onClick={addRealSenseRosTopic}>+ Add RealSense topics</Link>
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="msgType">Choose ROS2 Message Type:</label>
                    <select id="msgType" name="ros2-message-types" className="form-control form-control-lg  form-select">
                      <optgroup label="Standard Messages (std_msgs)">
                        <option value="std_msgs/msg/String">std_msgs/msg/String</option>
                        <option value="std_msgs/msg/Int8">std_msgs/msg/Int8</option>
                        <option value="std_msgs/msg/Int16">std_msgs/msg/Int16</option>
                        <option value="std_msgs/msg/Int32">std_msgs/msg/Int32</option>
                        <option value="std_msgs/msg/Int64">std_msgs/msg/Int64</option>
                        <option value="std_msgs/msg/UInt8">std_msgs/msg/UInt8</option>
                        <option value="std_msgs/msg/UInt16">std_msgs/msg/UInt16</option>
                        <option value="std_msgs/msg/UInt32">std_msgs/msg/UInt32</option>
                        <option value="std_msgs/msg/UInt64">std_msgs/msg/UInt64</option>
                        <option value="std_msgs/msg/Float32">std_msgs/msg/Float32</option>
                        <option value="std_msgs/msg/Float64">std_msgs/msg/Float64</option>
                        <option value="std_msgs/msg/Bool">std_msgs/msg/Bool</option>
                        <option value="std_msgs/msg/Header">std_msgs/msg/Header</option>
                        <option value="std_msgs/msg/Empty">std_msgs/msg/Empty</option>
                      </optgroup>

                      <optgroup label="Geometry Messages (geometry_msgs)">
                        <option value="geometry_msgs/msg/Point">geometry_msgs/msg/Point</option>
                        <option value="geometry_msgs/msg/Vector3">geometry_msgs/msg/Vector3</option>
                        <option value="geometry_msgs/msg/Pose">geometry_msgs/msg/Pose</option>
                        <option value="geometry_msgs/msg/PoseStamped">geometry_msgs/msg/PoseStamped</option>
                        <option value="geometry_msgs/msg/PoseWithCovariance">geometry_msgs/msg/PoseWithCovariance</option>
                        <option value="geometry_msgs/msg/PoseWithCovarianceStamped">geometry_msgs/msg/PoseWithCovarianceStamped</option>
                        <option value="geometry_msgs/msg/Twist">geometry_msgs/msg/Twist</option>
                        <option value="geometry_msgs/msg/TwistStamped">geometry_msgs/msg/TwistStamped</option>
                        <option value="geometry_msgs/msg/TwistWithCovariance">geometry_msgs/msg/TwistWithCovariance</option>
                        <option value="geometry_msgs/msg/TwistWithCovarianceStamped">geometry_msgs/msg/TwistWithCovarianceStamped</option>
                        <option value="geometry_msgs/msg/Quaternion">geometry_msgs/msg/Quaternion</option>
                        <option value="geometry_msgs/msg/Transform">geometry_msgs/msg/Transform</option>
                        <option value="geometry_msgs/msg/TransformStamped">geometry_msgs/msg/TransformStamped</option>
                      </optgroup>

                      <optgroup label="Sensor Messages (sensor_msgs)">
                        <option value="sensor_msgs/msg/Image">sensor_msgs/msg/Image</option>
                        <option value="sensor_msgs/msg/CompressedImage">sensor_msgs/msg/CompressedImage</option>
                        <option value="sensor_msgs/msg/LaserScan">sensor_msgs/msg/LaserScan</option>
                        <option value="sensor_msgs/msg/PointCloud2">sensor_msgs/msg/PointCloud2</option>
                        <option value="sensor_msgs/msg/Imu">sensor_msgs/msg/Imu</option>
                        <option value="sensor_msgs/msg/NavSatFix">sensor_msgs/msg/NavSatFix</option>
                        <option value="sensor_msgs/msg/CameraInfo">sensor_msgs/msg/CameraInfo</option>
                        <option value="sensor_msgs/msg/Range">sensor_msgs/msg/Range</option>
                        <option value="sensor_msgs/msg/BatteryState">sensor_msgs/msg/BatteryState</option>
                        <option value="sensor_msgs/msg/Joy">sensor_msgs/msg/Joy</option>
                      </optgroup>

                      <optgroup label="Navigation Messages (nav_msgs)">
                        <option value="nav_msgs/msg/Odometry">nav_msgs/msg/Odometry</option>
                        <option value="nav_msgs/msg/OccupancyGrid">nav_msgs/msg/OccupancyGrid</option>
                        <option value="nav_msgs/msg/Path">nav_msgs/msg/Path</option>
                        <option value="nav_msgs/msg/MapMetaData">nav_msgs/msg/MapMetaData</option>
                      </optgroup>

                      <optgroup label="Action Messages (action_msgs)">
                        <option value="action_msgs/msg/GoalStatus">action_msgs/msg/GoalStatus</option>
                        <option value="action_msgs/msg/GoalInfo">action_msgs/msg/GoalInfo</option>
                      </optgroup>

                      <optgroup label="Diagnostic Messages (diagnostic_msgs)">
                        <option value="diagnostic_msgs/msg/DiagnosticArray">diagnostic_msgs/msg/DiagnosticArray</option>
                        <option value="diagnostic_msgs/msg/DiagnosticStatus">diagnostic_msgs/msg/DiagnosticStatus</option>
                      </optgroup>

                      <optgroup label="Visualization Messages (visualization_msgs)">
                        <option value="visualization_msgs/msg/Marker">visualization_msgs/msg/Marker</option>
                        <option value="visualization_msgs/msg/MarkerArray">visualization_msgs/msg/MarkerArray</option>
                        <option value="visualization_msgs/msg/InteractiveMarker">visualization_msgs/msg/InteractiveMarker</option>
                      </optgroup>

                      <optgroup label="TF2 Messages (tf2_msgs)">
                        <option value="tf2_msgs/msg/TFMessage">tf2_msgs/msg/TFMessage</option>
                      </optgroup>

                      <optgroup label="Built-in & Example Interfaces">
                        <option value="builtin_interfaces/msg/Time">builtin_interfaces/msg/Time</option>
                        <option value="builtin_interfaces/msg/Duration">builtin_interfaces/msg/Duration</option>
                        <option value="example_interfaces/msg/*">example_interfaces/msg/*</option>
                      </optgroup>

                    </select>


                  </div>
                  <div className="col-md-5">
                    <label htmlFor="msgType">Add Topic Name:</label>
                    <input className="form-control form-control-lg" type="text" placeholder="Message Topic Name" id="msgTopic"/>
                  </div>
                  <div className="col-md-1">
                    <br/>
                    <button className="btn btn-outline-light btn-lg" onClick={() => addRosTopic()}>
                      Add
                    </button>
                  </div>
                </div>

                {postRosTopics ? postRosTopics.map((topic) => (
                  <div >{topic.type} = {topic.topic} &nbsp;
                  <button className="btn btn-outline-light btn-lg" onClick={() => deleteRosTopic(topic.topic)}>
                    Delete
                  </button>
                  </div>
                )) : ""}
                <br/>
                <button className="btn btn-outline-light btn-lg" onClick={updateRobot}>
                  Submit
                </button>
                <br/><br/><br/><br/><br/><br/>
                </>
              :
                ""
              }
            </div>
          </div>
        </div>
      </header><br/><br/><br/><br/><br/><br/>
    </div>
  );
}

export default Robots;
