import * as process from 'process'; // needed for p2p

import React, { useEffect, useState, useRef }  from "react";
import { NavLink, useNavigate } from 'react-router-dom'

import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";

import { Joystick } from 'react-joystick-component-godofkim';
import io from 'socket.io-client';
import "./../App.css";

// import Peer from 'simple-peer';
import LZString from 'lz-string';
import ReactGA from 'react-ga4';
// import { log } from 'console';

const TRACKING_ID = "G-WQXKE0M3J5";
const { REACT_APP_NODE_ENV, REACT_APP_IP_ADDRESS } = process.env;

var socket;
var peer;
window.global = window;
window.process = process;
window.Buffer = [];

let receivedChunks = [];
let totalChunks = null;
var serverUrl;

const messages = new Map();

// Define gamepad controller outside of any component or hook
const Control = () => {

  const navigate = useNavigate();

  const robotRef = useRef('');
  const tokenRef = useRef('');
  const gamepadDirectionRef = useRef('STOP');
  const setupCompleteRef = useRef(false); // Add this line
  const dataChannelRef = useRef(null); // Add a ref to hold the mutable data channel reference

  const [speed, setSpeed] = useState(20);
  const [lastStick, setLastStick] = useState(null);
  const [lastGamepad, setLastGamepad] = useState('STOP');
  const [uid, setUid] = useState('');
  const [token, setToken] = useState('');
  const [robot, setRobot] = useState('');
  const [robots, setRobots] = useState([]);
  const [rgbStream, setRgbStream] = useState('');
  const [presence, setPresence] = useState(false);
  const [p2p, setP2p] = useState(false);
  const [socketRef, setSocketRef] = useState(null);
  const [channelRef, setChannelRef] = useState(null);
  const [gamepadInterval, setGamepadInterval] = useState(null);
  const [gamepadActive, setGamepadActive] = useState(false);
  const [currentConnectedRobot, setCurrentConnectedRobot] = useState(null);
  const [connectionState, setConnectionState] = useState('disconnected'); // 'disconnected', 'checking', 'connecting', 'connected'

  const setRobotWithRef = (newRobot) => {
    robotRef.current = newRobot;
    setRobot(newRobot);
  };

  const setTokenWithRef = (newToken) => {
    tokenRef.current = newToken;
    setToken(newToken);
  };

  async function getRobots(userid){
    const q = query(collection(db, "robots"), where("uid", "==", userid));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size > 0){
      let robotList = [];
      querySnapshot.forEach((doc) => {
        // console.log(doc.data());
        var robotX = doc.data();
        robotX.id = doc.id;
        robotList.push(robotX);

        // // Moving from cloud streams to P2P
        // socket.on(`${robotX.id}/camera2d`, (message) => {
        //   // console.log(message);
        //   if(document.getElementById("mrRobot") && message.id === document.getElementById("mrRobot").value){
        //     setRgbStream("data:image/png;base64, " + message.data.data);
        //   } else {
        //     setRgbStream("data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=")
        //   }
        // });

      });
      setRobots(robotList);
    } else {
      setRobots([]);
    }
  }

  const getUserToken = async (uid) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists) {
      var user = docSnap.data();
      setTokenWithRef(user.apiToken)
    }
  }

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/control", title: "Control Page" });

      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUid(user.uid);
          getRobots(user.uid);
          getUserToken(user.uid);
        } else {
          console.log("user is logged out")
          navigate("/")
        }
      });

      // Set up server URL
      if (REACT_APP_NODE_ENV === 'development') {
        if(REACT_APP_IP_ADDRESS){
          serverUrl = `http://${REACT_APP_IP_ADDRESS}:3001`;
        } else {
          serverUrl = 'http://localhost:3001';
        }
      }
      if (process.env.NODE_ENV === 'production') {
        serverUrl = 'https://robotics.dev';
      }

      // Cleanup on unmount
      return () => {
        cleanupExistingConnections();
      };
  }, []);

  // Remove the automatic robot presence check useEffect
  // Remove the automatic socket connection useEffect

  // Update handleRobotChange to handle all connection logic
  const handleRobotChange = async (e) => {
    const newRobotId = e.target.value;
    console.log('[Selection] Robot selected:', newRobotId);

    // Store the previously connected robot ID before cleanup
    const prevConnectedRobot = currentConnectedRobot;

    // Always clean up existing connections first
    await cleanupExistingConnections();

    // If empty selection, just return after cleanup
    if (!newRobotId) {
      setRobotWithRef('');
      setCurrentConnectedRobot(null); // Explicitly clear the current robot
      return;
    }

    // Set new robot ID and update state
    setRobotWithRef(newRobotId);
    setCurrentConnectedRobot(newRobotId); // Set as current robot right away
    setConnectionState('checking');

    // Check robot presence
    try {
      console.log('[Presence] Checking if robot is online:', newRobotId);
      const response = await fetch(`/presence/${newRobotId}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'api_token': tokenRef.current
        },
      });

      const presenceData = await response.json();
      console.log('[Presence] Robot status:', presenceData);

      // Update presence state immediately
      setPresence(!!presenceData.online);

      if (!presenceData.online) {
        console.log('[Presence] Robot is offline - not connecting');
        setConnectionState('disconnected');
        return;
      }

      // Only proceed with connection if robot is online
      console.log('[Connection] Robot is online - establishing connection');
      setConnectionState('connecting');

      // Create signaling server connection
      const socketInstance = io(serverUrl, {
        query: {
          id: `browser-${Date.now()}`,
          roomId: newRobotId,
          robot: newRobotId,
          token: tokenRef.current
        },
        transports: ['websocket'],
        forceNew: true
      });

      socketInstance.on('connect', () => {
        console.log('[Socket] Connected to signaling server');
        setSocketRef(socketInstance);
        initiatePeerConnection(newRobotId, socketInstance);
      });

      socketInstance.on('connect_error', (error) => {
        console.error('[Socket] Connection error:', error);
        setConnectionState('disconnected');
        cleanupExistingConnections();
      });

    } catch (error) {
      console.error('[Selection] Error during robot selection:', error);
      setConnectionState('disconnected');
      setPresence(false);
      await cleanupExistingConnections();
    }
  };

  // Update cleanupExistingConnections to handle socket intervals
  const cleanupExistingConnections = async () => {
    console.log('[Cleanup] Starting cleanup of existing connections');

    // Clear connection states
    setP2p(false);
    setPresence(false);
    setRgbStream('');
    setCurrentConnectedRobot(null);
    setConnectionState('disconnected');

    // Reset any global message state that might persist
    window.messages = new Map(); // Reset chunked message tracking

    // Close data channel with proper cleanup
    if (dataChannelRef.current) {
      console.log('[Cleanup] Closing data channel');
      try {
        // Clear any intervals on the data channel
        if (dataChannelRef.current.pingInterval) {
          clearInterval(dataChannelRef.current.pingInterval);
        }
        // if (dataChannelRef.current.frameCheckInterval) {
        //   clearInterval(dataChannelRef.current.frameCheckInterval);
        // }

        dataChannelRef.current.close();
      } catch (e) {
        console.error('[Cleanup] Error closing data channel:', e);
      }
      dataChannelRef.current = null;
      setChannelRef(null);
    }

    // Clean up peer connection if it exists
    if (window.peerConnection) {
      console.log('[Cleanup] Cleaning up peer connection');
      try {
        // Clear any timeouts/intervals
        if (window.peerConnection.connectionTimeout) {
          clearTimeout(window.peerConnection.connectionTimeout);
        }
        if (window.peerConnection.iceRestartTimeout) {
          clearTimeout(window.peerConnection.iceRestartTimeout);
        }
        if (window.peerConnection.connectionCheckTimer) {
          clearInterval(window.peerConnection.connectionCheckTimer);
        }

        window.peerConnection.close();
        window.peerConnection = null;
      } catch (e) {
        console.error('[Cleanup] Error closing peer connection:', e);
      }
    }

    // Close WebSocket connection
    if (socketRef) {
      console.log('[Cleanup] Closing websocket connection');
      try {
        socketRef.disconnect();
        socketRef.close();
        setSocketRef(null); // Immediately clear the ref to prevent reconnection attempts
      } catch (e) {
        console.error('[Cleanup] Error closing socket:', e);
      }
    }

    // Wait a moment to ensure cleanup is complete
    await new Promise(resolve => setTimeout(resolve, 100));
    console.log('[Cleanup] Cleanup complete');
  };

  // Completely separate gamepad handling from useEffect
  const setupGamepad = () => {
    // console.log('Setting up gamepad controls');

    // Clean up any existing interval
    if (gamepadInterval) {
      clearInterval(gamepadInterval);
    }

    // First ensure we have the minimum requirements
    if (!robotRef.current) {
      console.warn('Cannot setup gamepad: No robot selected');
      return;
    }

    if (!tokenRef.current) {
      console.warn('Cannot setup gamepad: No token available');
      return;
    }

    // Make sure we have a gamepad
    const gamepads = navigator.getGamepads ? navigator.getGamepads() : [];
    let activeGamepad = null;

    for (let i = 0; i < gamepads.length; i++) {
      if (gamepads[i]) {
        activeGamepad = gamepads[i];
        break;
      }
    }

    if (!activeGamepad) {
      console.warn('Cannot setup gamepad: No gamepad detected');
      setGamepadActive(false);
      return;
    }

    // console.log(`Setting up gamepad controls for robot ${robotRef.current} with gamepad ${activeGamepad.id}`);

    // Set up the interval to check gamepad state
    const interval = setInterval(() => {
      // Re-query gamepads to get fresh state
      const controllers = navigator.getGamepads ? navigator.getGamepads() : [];
      let controller = null;

      // Find the first connected gamepad
      for (let i = 0; i < controllers.length; i++) {
        if (controllers[i]) {
          controller = controllers[i];
          break;
        }
      }

      if (!controller) {
        console.warn('Gamepad disconnected during interval');
        setGamepadActive(false);
        clearInterval(interval);
        return;
      }

      // Process gamepad input
      const x = Math.trunc(controller.axes[0] * 100) / 100;
      const y = Math.trunc(controller.axes[1] * 100) / 100;
      const speederElement = document.getElementById("speeder");
      const speeder = speederElement ? parseInt(speederElement.value) : 20;

      let gamepadDirection = 'STOP';
      let twistMsg = { linear: { x: 0.0, y: 0.0, z: 0.0 }, angular: { x: 0.0, y: 0.0, z: 0.0 }};

      if (Math.abs(x) < 0.1 && Math.abs(y) < 0.1) {
        gamepadDirection = 'STOP';
      } else if (x > 0.2) {
        twistMsg = { linear: { x: 0.0, y: 0.0, z: 0.0 }, angular: { x: 0.0, y: 0.0, z: -speeder / 100 } };
        gamepadDirection = 'RIGHT';
      } else if (x < -0.2) {
        twistMsg = { linear: { x: 0.0, y: 0.0, z: 0.0 }, angular: { x: 0.0, y: 0.0, z: speeder / 100 } };
        gamepadDirection = 'LEFT';
      } else if (y > 0.2) {
        twistMsg = { linear: { x: -speeder / 100, y: 0.0, z: 0.0 }, angular: { x: 0.0, y: 0.0, z: 0.0 } };
        gamepadDirection = 'BACKWARD';
      } else if (y < -0.2) {
        twistMsg = { linear: { x: speeder / 100, y: 0.0, z: 0.0 }, angular: { x: 0.0, y: 0.0, z: 0.0 } };
        gamepadDirection = 'FORWARD';
      };
      // console.log(x,y,gamepadDirection);

      // Only send command if direction has changed
      if (gamepadDirection !== gamepadDirectionRef.current) {
        gamepadDirectionRef.current = gamepadDirection;

        // Get the current robot ID
        const currentRobotId = document.getElementById("mrRobot")?.value || robotRef.current;
        // console.log(`Gamepad direction changed: ${gamepadDirection}, Robot ID: ${currentRobotId}`);

        // Send the command
        sendCommand(twistMsg, currentRobotId);
      }
    }, 100); // 10Hz update rate

    // Save the interval ID
    setGamepadInterval(interval);
    setGamepadActive(true);
    // console.log('Gamepad setup complete - interval running');
  };

  // Common function to send commands through either datachannel or socket
  const sendCommand = (twistMsg, explicitRobotId = null) => {
    const robotId = explicitRobotId || document.getElementById("mrRobot")?.value || robotRef.current;

    // console.log(`Sending command to robot: ${robotId}`);

    // Try datachannel first if it's available and open
    if (dataChannelRef.current && dataChannelRef.current.readyState === 'open') {
      try {
        const message = JSON.stringify({
          topic: 'twist',
          robotId: robotId,
          twist: twistMsg
        });

        dataChannelRef.current.send(message);
        // console.log('[P2P] Sent twist via data channel');
        return; // Success! Exit early
      } catch (error) {
        console.error("[P2P] Data channel send failed:", error);
        // Fall through to socket
      }
    }

    // Default to socket as reliable fallback
    sendViaSocket(twistMsg, robotId);
  };

  // Add a debugging function to check datachannel status
  const debugConnectionStatus = () => {
    console.log('=== Connection Status ===');
    console.log('P2P active:', p2p);
    console.log('Robot ID:', robotRef.current || 'none');
    console.log('Robot presence:', presence ? 'online' : 'offline');
    console.log('Data channel:', dataChannelRef.current ? {
      exists: true,
      readyState: dataChannelRef.current.readyState,
      open: dataChannelRef.current.readyState === 'open'
    } : 'not initialized');
    console.log('Socket:', socketRef ? {
      exists: true,
      connected: socketRef.connected,
      id: socketRef.id
    } : 'not connected (this is normal until robot selected and online)');
    console.log('Connection state:', connectionState);
    console.log('========================');
  };

  // Create a direct socket connection function that works independently of P2P
  const createDirectSocketConnection = () => {
    // Only create if we have the minimum requirements
    if (!robotRef.current || !tokenRef.current || !serverUrl) {
      console.warn('Cannot create direct socket: Missing requirements');
      return null;
    }

    console.log('Creating DIRECT socket connection for immediate use');

    // Create a fresh new socket (don't reuse the signaling socket)
    const directSocket = io(serverUrl, {
      query: {
        robot: robotRef.current,
        token: tokenRef.current,
        purpose: 'direct_control' // Mark this as a dedicated control socket
      },
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 500,
      timeout: 5000,
      forceNew: true // Force a completely new connection
    });

    directSocket.on('connect', () => {
      console.log(`✅ Control socket connected with ID: ${directSocket.id}`);
      // Store directly in state and ref for immediate access
      setSocketRef(directSocket);

      // Test the connection with a ping
      directSocket.emit('ping', {
        timestamp: Date.now(),
        robot: robotRef.current
      });
    });

    directSocket.on('pong', (data) => {
      console.log('Socket connection verified with pong:', data);
    });

    directSocket.on('connect_error', (error) => {
      console.error('❌ Control socket connection error:', error);
    });

    directSocket.on('error', (error) => {
      console.error('❌ Control socket error:', error);
    });

    return directSocket;
  };

// Modify the handleKeyDown method to implement WASDX key controls
const handleKeyDown = (event) => {
  // Only process if we have valid robot and token
  if (!robotRef.current || !tokenRef.current) return;

  // Get current speed from the slider
  const speederElement = document.getElementById("speeder");
  const speeder = speederElement ? parseInt(speederElement.value) : 20;

  let twistMsg = null;
  let direction = '';

  // Map keys to twist messages (case insensitive)
  switch (event.key.toUpperCase()) {
    case 'W': // Forward
      twistMsg = {linear: {x: speeder/100, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}};
      direction = 'FORWARD';
      break;
    case 'A': // Left
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: speeder/100}};
      direction = 'LEFT';
      break;
    case 'D': // Right
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: -speeder/100}};
      direction = 'RIGHT';
      break;
    // case 'S': // Stop
    //   twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}};
    //   direction = 'STOP';
    //   break;
    case 'S': // Backward
      twistMsg = {linear: {x: -speeder/100, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}};
      direction = 'BACKWARD';
      break;
    default:
      return; // Ignore other keys
  }

  // Only send if we have a valid twist message
  if (twistMsg) {
    // Get the current robot ID
    const currentRobotId = document.getElementById("mrRobot")?.value || robotRef.current;
    // console.log(`Keyboard control: ${direction}, Robot ID: ${currentRobotId}`);

    // Send the command
    sendCommand(twistMsg, currentRobotId);

    // Update last stick for UI consistency
    setLastStick(direction);
  }
};

// Add a handleKeyUp method to stop movement when keys are released
const handleKeyUp = (event) => {
  // Only process if we have valid robot and token
  if (!robotRef.current || !tokenRef.current) return;

  // Only handle the keys we care about
  const keys = ['W', 'A', 'S', 'D', 'X'];
  if (!keys.includes(event.key.toUpperCase())) return;

  // Send stop command
  const twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}};
  const currentRobotId = document.getElementById("mrRobot")?.value || robotRef.current;
  // console.log(`Keyboard control: STOP, Robot ID: ${currentRobotId}`);
  sendCommand(twistMsg, currentRobotId);

  // Update last stick for UI consistency
  setLastStick('STOP');
};

  // Add function to initiate peer connection
  const initiatePeerConnection = async (robotId, socket) => {
    try {
      console.log('[P2P] Initiating peer connection for robot:', robotId);

      // Use simplified configuration like in control-working.js
      const peerConnection = new RTCPeerConnection({
        iceServers: [
          { urls: 'stun:stun1.l.google.com:19302' }
        ],
        iceCandidatePoolSize: 0 // Disable candidate pooling for compatibility
      });

      // Store globally for access in cleanup
      window.peerConnection = peerConnection;

      // Create datachannel first with simple configuration
      const dataChannel = peerConnection.createDataChannel('robotics', {
        negotiated: true,  // Both sides must use the same ID
        id: 1,
        ordered: true,
        protocol: 'chat' // Add this from working example
      });

      setupDataChannelHandlers(dataChannel, robotId);

      // Set up connection state monitors
      peerConnection.onconnectionstatechange = () => {
        console.log('[P2P] Connection state changed:', peerConnection.connectionState);

        if (peerConnection.connectionState === 'connected') {
          console.log('[P2P] Connection established successfully!');
          setConnectionState('connected');

          if (peerConnection.connectionTimeout) {
            clearTimeout(peerConnection.connectionTimeout);
          }
        }
        else if (peerConnection.connectionState === 'failed' ||
                peerConnection.connectionState === 'closed') {
          console.log('[P2P] Connection failed or closed');
          fallbackToSocketMode();
        }
      };

      peerConnection.oniceconnectionstatechange = () => {
        console.log('[P2P] ICE connection state changed:', peerConnection.iceConnectionState);

        if (peerConnection.iceConnectionState === 'connected' ||
            peerConnection.iceConnectionState === 'completed') {
          console.log('[P2P] ICE connection established!');
          setConnectionState('connected');
        }
        else if (peerConnection.iceConnectionState === 'failed') {
          console.log('[P2P] ICE connection failed, attempting restart');
          try {
            peerConnection.restartIce();
          } catch(e) {
            console.error('[P2P] ICE restart failed:', e);
            fallbackToSocketMode();
          }
        }
      };

      // Handle ICE candidates like in control-working.js
      peerConnection.onicecandidate = ({ candidate }) => {
        if (!candidate || !socket?.connected) return;

        console.log('[P2P] Got local ICE candidate');
        socket.emit('signal', {
          type: 'candidate',
          candidate: candidate.candidate,
          sdpMLineIndex: candidate.sdpMLineIndex || 0,
          sdpMid: candidate.sdpMid || '0',
          targetPeer: robotId,
          sourcePeer: socket.id
        });
      };

      // Set up signal handler for received messages from robot
      socket.on('signal', async (message) => {
        // Check if the message is intended for us and from the robot
        if (!message || message.sourcePeer !== robotId) {
          return;
        }

        console.log(`[P2P] Received signal from robot: ${message.type}`);

        try {
          switch (message.type) {
            case 'answer':
              // This is the response to our offer - set it as remote description
              try {
                await peerConnection.setRemoteDescription({
                  type: 'answer',
                  sdp: message.sdp
                });
                console.log('[P2P] Remote description set successfully');
              } catch (e) {
                console.error('[P2P] Failed to set remote description:', e);
                fallbackToSocketMode();
              }
              break;

            case 'offer':
              // We're not expecting this since we initiated the connection
              // Ignore it as indicated in working code
              console.log('[P2P] Ignoring offer from robot - we are the offerer');
              break;

            case 'candidate':
              if (!message.candidate) {
                console.log('[P2P] Received empty candidate - end of candidates');
                return;
              }

              try {
                // Only add candidates if we have remote description
                if (peerConnection.remoteDescription) {
                  await peerConnection.addIceCandidate(new RTCIceCandidate({
                    candidate: message.candidate,
                    sdpMLineIndex: message.sdpMLineIndex || 0,
                    sdpMid: message.sdpMid || "0"
                  }));
                  console.log('[P2P] Added ICE candidate from robot');
                } else {
                  console.log('[P2P] Skipping ICE candidate - no remote description yet');
                }
              } catch (e) {
                console.warn('[P2P] Failed to add ICE candidate:', e);
              }
              break;
          }
        } catch (error) {
          console.error('[P2P] Signal processing error:', error);
          fallbackToSocketMode();
        }
      });

      // Create and send offer - using similar approach to control-working.js
      const offer = await peerConnection.createOffer({
        offerToReceiveAudio: false,
        offerToReceiveVideo: false  // Match working version - don't request video in offer
      });

      await peerConnection.setLocalDescription(offer);

      console.log('[P2P] Sending offer to robot');
      socket.emit('signal', {
        type: 'offer',
        sdp: offer.sdp,
        targetPeer: robotId,
        sourcePeer: socket.id
      });

      // Add connection timeout with fallback to socket communication
      const connectionTimeout = setTimeout(() => {
        if (peerConnection.iceConnectionState !== 'connected' &&
            peerConnection.iceConnectionState !== 'completed') {
          console.log('[P2P] Connection timeout after 15 seconds - falling back to socket');
          fallbackToSocketMode();
        }
      }, 15000);

      // Store timeout for cleanup
      peerConnection.connectionTimeout = connectionTimeout;

      // Function to handle fallback to socket mode
      function fallbackToSocketMode() {
        setConnectionState('socket-fallback');
        setP2p(false);

        // If this was the current robot, keep socket for commands
        if (socket?.connected) {
          console.log('[P2P] Keeping socket for fallback communication');
          setSocketRef(socket);
        } else {
          cleanupExistingConnections();
        }
      }

      // Update connection tracking
      setCurrentConnectedRobot(robotId);
      setConnectionState('connecting');

    } catch (error) {
      console.error('[P2P] Setup error:', error);
      setConnectionState('disconnected');
      cleanupExistingConnections();
    }
  };

  // Improve WebRTC connection handlers with better error recovery
  const setupPeerConnectionHandlers = (peerConnection, socket, robotId) => {
    // Track our reconnection attempts
    let reconnectAttempts = 0;
    const MAX_RECONNECT_ATTEMPTS = 2;

    peerConnection.onicecandidate = ({ candidate }) => {
      if (!candidate || !socket?.connected) return;

      console.log('[P2P] Got local ICE candidate');

      // Send all ICE candidates to the robot with all required fields
      socket.emit('signal', {
        type: 'candidate',
        candidate: candidate.candidate,
        sdpMLineIndex: candidate.sdpMLineIndex || 0,
        sdpMid: candidate.sdpMid || '0',
        usernameFragment: candidate.usernameFragment,
        targetPeer: robotId,
        sourcePeer: socket.id
      });
    };

    // Add ice gathering state monitoring
    peerConnection.onicegatheringstatechange = () => {
      console.log('[P2P] ICE gathering state:', peerConnection.iceGatheringState);

      if (peerConnection.iceGatheringState === 'complete') {
        console.log('[P2P] ICE gathering completed');
      }
    };

    peerConnection.onconnectionstatechange = () => {
      console.log('[P2P] Connection state changed:', peerConnection.connectionState);

      if (peerConnection.connectionState === 'connected') {
        console.log('[P2P] Connection established successfully!');
        reconnectAttempts = 0; // Reset reconnection counter on success
        setConnectionState('connected');

        // Clear timeout if it exists
        if (peerConnection.connectionTimeout) {
          clearTimeout(peerConnection.connectionTimeout);
          peerConnection.connectionTimeout = null;
        }
      }
      else if (peerConnection.connectionState === 'failed' ||
              peerConnection.connectionState === 'closed') {
        console.log('[P2P] Connection failed or closed');

        // Only attempt ice restart if we haven't exceeded max attempts
        if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
          reconnectAttempts++;
          console.log(`[P2P] Attempting reconnection ${reconnectAttempts}/${MAX_RECONNECT_ATTEMPTS}`);

          try {
            // Try ICE restart
            peerConnection.restartIce();

            // Create a new offer with iceRestart flag
            setTimeout(async () => {
              try {
                const newOffer = await peerConnection.createOffer({ iceRestart: true });
                await peerConnection.setLocalDescription(newOffer);

                socket.emit('signal', {
                  type: 'offer',
                  sdp: newOffer.sdp,
                  targetPeer: robotId,
                  sourcePeer: socket.id,
                  isRestart: true
                });
                console.log('[P2P] Sent ICE restart offer');
              } catch (e) {
                console.error('[P2P] Failed to create restart offer:', e);
                fallbackToSocketMode();
              }
            }, 1000);
          } catch (error) {
            console.error('[P2P] Failed to restart ICE:', error);
            fallbackToSocketMode();
          }
        } else {
          console.log('[P2P] Max reconnection attempts reached, falling back to socket mode');
          fallbackToSocketMode();
        }
      }
    };

    // Helper function to switch to socket fallback mode
    const fallbackToSocketMode = () => {
      setConnectionState('socket-fallback');
      setP2p(false);

      // If this was the current robot, keep socket for commands
      if (currentConnectedRobot === robotId && socket?.connected) {
        console.log('[P2P] Keeping signaling socket for fallback communication');
        setSocketRef(socket);
      } else {
        cleanupExistingConnections();
      }
    };

    peerConnection.oniceconnectionstatechange = () => {
      console.log('[P2P] ICE connection state changed:', peerConnection.iceConnectionState);

      if (peerConnection.iceConnectionState === 'connected' ||
          peerConnection.iceConnectionState === 'completed') {
        console.log('[P2P] ICE connection established!');
        setConnectionState('connected');

        // Clear connection timeout if it exists
        if (peerConnection.connectionTimeout) {
          clearTimeout(peerConnection.connectionTimeout);
          peerConnection.connectionTimeout = null;
        }
      }
      else if (peerConnection.iceConnectionState === 'failed') {
        console.log('[P2P] ICE connection failed');

        if (currentConnectedRobot === robotId) {
          // Try ICE restart if the connection failed
          try {
            console.log('[P2P] Attempting ICE restart...');
            peerConnection.restartIce();

            // Set a timeout for the ICE restart
            const iceRestartTimeout = setTimeout(() => {
              if (peerConnection.iceConnectionState !== 'connected' &&
                  peerConnection.iceConnectionState !== 'completed') {
                console.log('[P2P] ICE restart failed, falling back to socket');
                setConnectionState('socket-fallback');
                setP2p(false);

                // Keep the signaling socket for commands
                if (socket && socket.connected) {
                  setSocketRef(socket);
                }
              }
            }, 8000); // 8 seconds to wait for ICE restart

            // Store the timeout
            peerConnection.iceRestartTimeout = iceRestartTimeout;
          } catch (error) {
            console.error('[P2P] ICE restart failed:', error);
            setConnectionState('socket-fallback');

            // Keep the signaling socket for commands
            if (socket && socket.connected) {
              setSocketRef(socket);
            } else {
              cleanupExistingConnections();
            }
          }
        }
      }
    };

    // Add a connection check timer
    peerConnection.connectionCheckTimer = setInterval(() => {
      if (peerConnection.connectionState === 'connected' &&
          peerConnection.iceConnectionState === 'connected') {
        return; // All good
      }

      // If we've been connecting for too long, try to restart
      if (peerConnection.connectionState === 'connecting' &&
          peerConnection.iceConnectionState !== 'connected' &&
          peerConnection.iceConnectionState !== 'completed') {

        // Connection is taking too long, try ice restart
        console.log('[P2P] Connection taking too long, attempting ICE restart');
        try {
          peerConnection.restartIce();
        } catch (error) {
          console.error('[P2P] Error restarting ICE:', error);
        }
      }
    }, 10000); // Check every 10 seconds
  };

  // Improve data channel handlers
  const setupDataChannelHandlers = (dataChannel, robotId) => {
    // Add message tracking for chunks like in control-working.js
    const robotMessages = new Map();

    dataChannel.onopen = () => {
      console.log('[P2P] Data channel opened for robot:', robotId);

      // Store the robot ID directly with the data channel for verification
      dataChannel.robotId = robotId;

      // Also update refs to ensure current connections are tracked
      dataChannelRef.current = dataChannel;
      setChannelRef(dataChannel);
      setP2p(true);
      setConnectionState('connected');

      // Make sure currentConnectedRobot is set correctly
      setCurrentConnectedRobot(robotId);

      // Request camera stream immediately using same format as control-working
      try {
        // console.log('[P2P] Requesting camera stream');
        // dataChannel.send(JSON.stringify({
        //   type: 'subscribe',
        //   topic: '/camera2d',
        //   robotId: robotId,
        //   options: {
        //     format: 'jpeg',
        //     quality: 75,
        //     width: 424,
        //     height: 240,
        //     fps: 10
        //   }
        // }));

        // // Send test message like in working example
        // dataChannel.send(JSON.stringify({
        //   type: 'hello',
        //   message: 'Channel connected'
        // }));

        // Set up keep-alive ping
        dataChannel.pingInterval = setInterval(() => {
          if (dataChannel.readyState === 'open') {
            try {
              dataChannel.send(JSON.stringify({
                type: 'ping',
                timestamp: Date.now()
              }));
            } catch (error) {
              console.error('[P2P] Error sending ping:', error);
            }
          } else {
            clearInterval(dataChannel.pingInterval);
          }
        }, 15000);
      } catch (e) {
        console.error('[P2P] Failed to request camera stream:', e);
      }
    };

    dataChannel.onclose = () => {
      console.log('[P2P] Data channel closed');
      setP2p(false);

      // Clear the ping interval if it exists
      if (dataChannel.pingInterval) {
        clearInterval(dataChannel.pingInterval);
        dataChannel.pingInterval = null;
      }

      if (currentConnectedRobot === robotId) {
        setCurrentConnectedRobot(null);
        setConnectionState('socket-fallback');
      }
    };

    // Implement the same chunked message handler as in control-working.js
    function handleChunkedMessage(data) {
      try {
        const msgData = JSON.parse(data);
        const { chunk, index, total, messageId } = msgData;

        if (!messages.has(messageId)) {
          messages.set(messageId, {
            receivedChunks: [],
            totalChunks: total,
          });
        }

        const currentMessage = messages.get(messageId);
        currentMessage.receivedChunks[index] = chunk;

        // Check if we have all chunks
        if (currentMessage.receivedChunks.filter(Boolean).length === currentMessage.totalChunks) {
          try {
            const fullData = currentMessage.receivedChunks.join('');
            const decompressed = LZString.decompressFromBase64(fullData);
            const originalData = JSON.parse(decompressed);
            messages.delete(messageId);
            return originalData;
          } catch (e) {
            console.error('[P2P] Decompression error:', e);
          }
        }
        return null;
      } catch (error) {
        console.error('[P2P] Chunk handling error:', error);
        return null;
      }
    }

    // Implement the message handler using the same logic as control-working.js
    dataChannel.onmessage = (event) => {
      // Skip processing if this channel's robot isn't the current selected one
      if (dataChannel.robotId !== robotRef.current) {
        // console.warn('[P2P] Received message for non-active robot, ignoring');
        return;
      }

      try {
        // Check if message is JSON
        var decompressed;
        if (typeof event.data === 'string' && event.data.startsWith('{')) {
          // Handle potential chunked messages
          decompressed = handleChunkedMessage(event.data);

          // Process regular JSON messages
          const data = decompressed || JSON.parse(event.data);

          // FIX: This was causing the issue - we should check against currentConnectedRobot
          // not robotRef.current which might have changed if user selected a different robot
          // in the UI but the established connection is still with the previous robot.

          // The key check: is this message from the robot associated with this data channel?
          const msgRobotId = data.robotId || (decompressed && decompressed.robotId);

          // Only validate robot ID if it's present in the message
          if (msgRobotId && msgRobotId !== dataChannel.robotId && msgRobotId !== currentConnectedRobot) {
            // console.warn(`[P2P] Received message for robot ${msgRobotId} but connected to ${dataChannel.robotId}, ignoring`);
            return;
          }

          // console.log('[P2P] Processing valid message from robot:', dataChannel.robotId, data);

          // 2D camera stream handling - exactly like control-working.js
          // if (data.topic === `/robot${robotRef.current.replace(/-/g, "")}/camera2d` || (decompressed && decompressed.topic === `/robot${robotRef.current.replace(/-/g, "")}/camera2d`)) {
          if (data.topic === '/camera2d' || (decompressed && decompressed.topic === '/camera2d')) {
            const imageData = data.data?.data || decompressed?.data?.data;
            if (imageData) {
              // console.log('[P2P] Received camera frame');
              // Format RGB stream exactly as in control-working.js
              setRgbStream(`data:image/png;base64, ${imageData}`);
              dataChannel.lastFrameTime = Date.now();
            }
          }

          // Handle ping/pong for keepalive
          if (data.type === 'pong') {
            const latency = Date.now() - data.timestamp;
            console.log(`[P2P] Ping latency: ${latency}ms`);
          }
        }
      } catch (error) {
        console.error('[P2P] Message handling error:', error);
      }
    };

    dataChannel.onerror = (error) => {
      console.error('[P2P] Data channel error:', error);

      // Only update state if this is the current robot
      if (currentConnectedRobot === robotId) {
        setP2p(false);
        setConnectionState('socket-fallback');
      }
    };

    // // Add frame timeout detection
    // dataChannel.frameCheckInterval = setInterval(() => {
    //   if (dataChannel.lastFrameTime) {
    //     const now = Date.now();
    //     if (now - dataChannel.lastFrameTime > 5000) {
    //       console.warn('[P2P] No frames received for 5 seconds, re-requesting stream');

    //       try {
    //         if (dataChannel.readyState === 'open') {
    //           dataChannel.send(JSON.stringify({
    //             type: 'subscribe',
    //             topic: '/camera2d',
    //             robotId: robotId,
    //             options: {
    //               format: 'jpeg',
    //               quality: 75,
    //               width: 424,
    //               height: 240,
    //               fps: 10
    //             }
    //           }));
    //         }
    //       } catch (e) {
    //         console.error('[P2P] Failed to re-request camera stream:', e);
    //       }
    //     }
    //   }
    // }, 5000);
  };

  // Also update the initial robot selection to trigger connection setup
  // Also update the gamepad event listener setup to reinitialize on robot change
  useEffect(() => {
    if (gamepadActive) {
      // Reinitialize gamepad when robot changes
      setupGamepad();
    }
  }, [robot]);

  // Add a new useEffect to reinitialize the gamepad when token changes
  useEffect(() => {
    if (tokenRef.current && robotRef.current && gamepadActive) {
      console.log('Token or robot changed, reinitializing gamepad');
      setupGamepad();
    }
  }, [token, robot]);

  // Add a more robust socket initialization function
  const createAndConnectSocket = (robotId, customToken = null) => {
    // Use the provided token or the current one from ref
    const authToken = customToken || tokenRef.current;

    if (!authToken) {
      console.warn('Cannot create socket: No authentication token');
      return null;
    }

    if (!robotId) {
      console.warn('Cannot create socket: No robot ID');
      return null;
    }

    console.log(`Creating direct socket connection for robot: ${robotId}`);

    // Close any existing socket
    if (socketRef) {
      console.log('Closing existing socket before creating a new one');
      socketRef.close();
    }

    // Create a new socket with the specific robot info
    const newSocket = io(serverUrl, {
      query: {
        robot: robotId,
        token: authToken
      },
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelayMax: 2000,
      timeout: 10000
    });

    newSocket.on('connect', () => {
      console.log(`Socket connected for robot ${robotId} with ID: ${newSocket.id}`);
    });

    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    newSocket.on('disconnect', (reason) => {
      console.log(`Socket disconnected: ${reason}`);
    });

    newSocket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    setSocketRef(newSocket);
    return newSocket;
  };

  // Modify the joy function to use the common sendCommand function
  const joy = (stick) => {
    if(stick.direction === lastStick) return;

    let twistMsg;
    if(stick.type === 'stop'){
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
    } else if (stick.direction === 'FORWARD') {
      twistMsg = {linear: {x: speed/100, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
    } else if (stick.direction === 'BACKWARD') {
      twistMsg = {linear: {x: -speed/100, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
    } else if (stick.direction === 'LEFT') {
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: speed/100}}
    } else if (stick.direction === 'RIGHT') {
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: -speed/100}}
    }

    setLastStick(stick.direction);

    // Get the current robot ID
    const currentRobotId = document.getElementById("mrRobot")?.value || robotRef.current;
    sendCommand(twistMsg, currentRobotId);
  };

  // Initialize gamepad when component mounts - improve gamepad detection
  useEffect(() => {
    // console.log('Setting up gamepad event listeners');

    // Use a more robust gamepad detection method
    const handleGamepadConnected = (event) => {
      console.log('Gamepad connected event fired:', event.gamepad.id);
      setGamepadActive(true);

      // Force setup with a small delay to ensure everything is ready
      setTimeout(() => {
        if (robotRef.current && tokenRef.current) {
          // console.log('Setting up gamepad after connection event');
          setupGamepad();
          setupCompleteRef.current = true;
        } else {
          console.warn('Gamepad connected but robot/token not ready yet');
        }
      }, 500);
    };

    const handleGamepadDisconnected = (event) => {
      console.log('Gamepad disconnected:', event.gamepad.id);
      if (gamepadInterval) {
        clearInterval(gamepadInterval);
        setGamepadInterval(null);
      }
      setGamepadActive(false);
      setupCompleteRef.current = false;
    };

    // Add event listeners
    window.addEventListener('gamepadconnected', handleGamepadConnected);
    window.addEventListener('gamepaddisconnected', handleGamepadDisconnected);

    // Manually check for connected gamepads (some browsers don't fire events reliably)
    const checkForGamepads = () => {
      const gamepads = navigator.getGamepads ? navigator.getGamepads() : [];
      let foundGamepad = false;

      for (let i = 0; i < gamepads.length; i++) {
        if (gamepads[i]) {
          // console.log(`Found already connected gamepad: ${gamepads[i].id}`);
          foundGamepad = true;
          setGamepadActive(true);

          if (robotRef.current && tokenRef.current && !setupCompleteRef.current) {
            // console.log('Setting up gamepad from manual detection');
            setupGamepad();
            setupCompleteRef.current = true;
          }
          break;
        }
      }

      return foundGamepad;
    };

    // Initial check for connected gamepads
    const initialGamepadCheck = setTimeout(() => {
      checkForGamepads();
    }, 1000);

    // Periodic gamepad check (for browsers that don't handle events well)
    const gamepadCheckInterval = setInterval(() => {
      if (!gamepadActive) {
        const found = checkForGamepads();
        if (found && robotRef.current && tokenRef.current) {
          // console.log('Found gamepad during periodic check');
          if (!setupCompleteRef.current) {
            setupGamepad();
            setupCompleteRef.current = true;
          }
        }
      }
    }, 3000);

    // Clean up
    return () => {
      window.removeEventListener('gamepadconnected', handleGamepadConnected);
      window.removeEventListener('gamepaddisconnected', handleGamepadDisconnected);
      clearTimeout(initialGamepadCheck);
      clearInterval(gamepadCheckInterval);
      if (gamepadInterval) {
        clearInterval(gamepadInterval);
      }
    };
  }, []);

  // Make the function fire unconditionally during development
  useEffect(() => {
    if (token && robot) {
      // Force setup for development/testing
      console.log('Forcing gamepad setup due to token/robot change');
      const setupForcedTimeout = setTimeout(() => {
        setupGamepad();
      }, 2000);

      return () => clearTimeout(setupForcedTimeout);
    }
  }, [token, robot]);

  // Add a new event listener to the socket to detect disconnection
  useEffect(() => {
    if (socketRef) {
      const disconnectHandler = (reason) => {
        console.log(`[Socket] Disconnected: ${reason}`);
        // Update connection state if this affects our current robot
        if (currentConnectedRobot && connectionState !== 'disconnected') {
          setConnectionState('disconnected');
          setPresence(false);
          setP2p(false);
        }
      };

      socketRef.on('disconnect', disconnectHandler);

      return () => {
        socketRef.off('disconnect', disconnectHandler);
      };
    }
  }, [socketRef, currentConnectedRobot, connectionState]);

  // Completely rewrite sendViaSocket function to focus on reliable socket or fetch-based communication
  const sendViaSocket = (twistMsg, explicitRobotId = null) => {
    const robotId = explicitRobotId || document.getElementById("mrRobot")?.value || robotRef.current;

    // Only send commands if we believe the robot is online
    if (!presence) {
      console.warn('[Command] Not sending command - robot appears to be offline');
      return;
    }

    // Use socket if available
    if (socketRef && socketRef.connected) {
      try {
        socketRef.emit("twist", {
          robot: robotId,
          token: tokenRef.current,
          twist: twistMsg
        });
        return; // Success - exit early
      } catch (error) {
        console.error('[Command] Error sending via socket:', error);
        // Fall through to fetch API
      }
    }

    // If we reach here, socket failed - use fetch API as last resort
    console.log('[Command] Using fetch API fallback for command');

    fetch(`${serverUrl}/twist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api_token': tokenRef.current
      },
      body: JSON.stringify({
        robot: robotId,
        twist: twistMsg
      })
    })
    .then(response => {
      if (response.ok) console.log('[Command] Sent via fetch API');
      else console.error('[Command] API failed:', response.statusText);
    })
    .catch(error => {
      console.error('[Command] API error:', error);
    });
  };

  return (
    <div className="App" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} tabIndex={0}>
      <header className="App-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <img alt="robot" src={`https://robohash.org/${robot ? robot : 1}?size=40x40`}/>
                {presence ?
                  <>
                  Online
                  {p2p ? ` : Peered ` : " : Peering"}
                  </>
                : "Offline"}
              </div>
              <div className="d-flex justify-content-center">
                {rgbStream ? <img src={rgbStream} width="424" height="240"/> : (
                  <div className="video-placeholder"
                    style={{
                      width: '424px',
                      height: '240px',
                      background: '#222',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#666',
                      border: '1px solid #444',
                      borderRadius: '4px',
                      position: 'relative'
                    }}>
                    <div>{presence ? (
                      p2p ? "Waiting for video stream..." : "Establishing peer connection..."
                    ) : "Robot offline"}</div>

                    {/* Add more detailed connection info */}
                    <div style={{fontSize: '10px', marginTop: '10px', color: '#888'}}>
                      DC: {dataChannelRef.current?.readyState || 'null'} |
                      Socket: {socketRef ? 'connected' : 'disconnected'} |
                    </div>
                  </div>
                )}
              </div>
              <br/>
              <div className="d-flex justify-content-center">
                <Joystick className="" size={100} sticky={false} baseColor="gray" stickColor="#F8E020" move={joy} stop={joy}></Joystick>
              </div>
              <label htmlFor="customRange1" className="form-label">Speed</label>
              <input type="range" className="form-range" id="speeder" min="0" max="100" value={speed} step="5" onChange={e => setSpeed(e.target.value)}/>
              {robots.length === 0 ? "No robots created yet..." :
              <select onChange={handleRobotChange} className="form-select" id="mrRobot" value={robot}>
                <option value="">Select a robot...</option>
                {robots.map((robotItem, index) => (
                  <option key={index} value={robotItem.id}>{robotItem.name}</option>
                ))}
              </select>
              }
              <div className="mt-2">
                Use virtual joystick or WASD keys or bluetooth gamepad <i className="fas fa-gamepad"></i> to control the selected robot.
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Control;
