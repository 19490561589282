import React, { useEffect, useState }  from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { CopyBlock, CodeBlock, dracula } from "react-code-blocks";
import "./../App.css";
import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Home() {
  const [join, setJoin] = useState(true);
  const [email, setEmail] = useState("");

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/home", title: "Home Page" });

      onAuthStateChanged(auth, (user) => {
          if (user) {
            setEmail(user.email);
          }
        });
  }, [])

  const joinToggle = (e) => {
    setJoin(false);
  }

  const onJoin = (e) => {
      e.preventDefault();
      if(email !== ""){
        // setDoc(doc(db, "waitlist", email), {
        //   allow: false
        // }, { merge: true })
        // .then(
        //   setJoin(true)
        // )

        fetch('/waitlist', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({"email": email})
        })
        .then(response => {
          // Handle the response
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Assuming the response is JSON
        })
        .then(data => {
          // Do something with the data
          // console.log(data);
          setJoin(true)
        })
        .catch(error => {
          // Handle any errors
          console.error('Error:', error);
        });

      } else {
        setJoin(true)
      }
  }

  return (
    <div className="App body-home">
      <header className="header-home">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <center>
                <div className="nodebot">
                  <div className="icon">
                    <div className="bobble"></div>
                    <div className="antenna"></div>
                    <div className="head">
                      <div className="eye left"></div>
                      <div className="eye right"></div>
                    </div>
                    <div className="neck"></div>
                    <div className="neck"></div>
                  </div>
                </div>
                <span className="logo-tag">&nbsp;.dev</span>
              </center>
            </div>
            <div className="col-sm-6">
              <center>
                <h2 className="brand2">ROBOTICS.DEV</h2>
                <p>P2P Robotics Developer & Operations Platform</p>
                <div className="cta">
                  {join ?
                    <center>
                    {email ? `${email} joined!` :
                    <button onClick={joinToggle} className="btn btn-danger btn-lg">
                      Join Waitlist
                    </button>
                    }
                    </center>
                  :
                    <>
                      <input
                          id="email-address"
                          name="email"
                          type="email"
                          required
                          className="form-control form-control-lg"
                          placeholder="Email address"
                          onChange={(e)=>setEmail(e.target.value)}
                      />
                      <button onClick={onJoin} className="btn btn-outline-light btn-lg" >
                          Join
                      </button>
                    </>
                  }
                </div>
              </center>
            </div>
          </div>
        </div>
      </header>

      <section className="platform-summary">
          <div className="section-content">
              <p><b>
              Connect, Code, and Control ROS robots remotely via secure peer-to-peer (P2P) connectivity, Teleop remote control, Node.JS and Python Robotics Developer Kits (RDK), REST APIs, and VSCode/Cursor Copilot AI.
              Develop, test, and deploy your new Robotics.dev P2P apps anywhere (cloud, edge, or on your robots)!
              </b></p>
          </div>
      </section>

      <section className="code-sample">
          <div className="section-content">
              <div className="features">
                  <div className="feature">
                      <center><i className="fas fa-globe"></i>
                      <h3>Global P2P Connectivity</h3></center>
                      <p>Extend ROS2 robotics capabilities beyond local area networks for worldwide robot control with our Web Teleop, Robotics Developer Kit, REST APIs, and Copilot AI on <b>Linux, Windows, and Mac!</b> </p>
                  </div>
                  <div className="feature">
                      <center><i className="fas fa-network-wired"></i>
                      <h3>P2P Compute</h3></center>
                      <p>Run AI-powered robotics apps peer-to-peer on the cloud or edge or your development environment, or even on the robot itself. Runs inside <b>Docker</b> containers anywhere too!</p>
                  </div>
                  <div className="feature">
                      <center><i className="fas fa-gamepad"></i>
                      <h3>P2P Web Teleop</h3></center>
                      <p>Supports Web Teleop control from anywhere in the world over browsers with P2P streaming video with both software and hardware <b>joysticks/gamepads</b>. Works great on <b>mobile</b> devices too!</p>
                  </div>
                  <div className="feature">
                      <center><i className="fas fa-robot"></i>
                      <i className="fas fa-robot"></i>
                      <h3>Multi-Robot Orchestration</h3></center>
                      <p>Develop and run AI tasks across fleets of robots or orchestrate multiple AI tasks on the same robot from anywhere with ease and without interrupting existing ROS apps running on the robot.</p>
                  </div>
                  <div className="feature">
                      <center><i className="fas fa-plug"></i>
                      <h3>Easy Integration</h3></center>
                      <p>Runs along side of existing ROS2 apps. Compatible with 2D and 3D depth <b>stereo cameras</b> and a wide range of hardware platforms. We also offer a powerful Robotics Developer Kit (<b>RDK</b>) as well as a simple and secure <b>REST API</b> and WebSocket support for developers!</p>
                  </div>
                  <div className="feature">
                      <center><i className="fas fa-code"></i>
                      <h3>Developer Tools</h3></center>
                      <p>Our developer tools include:
                      <br/>
                        <ul>
                          <li>VSCode/Cursor Extension <a href="https://marketplace.visualstudio.com/items?itemName=chrismatthieu.robotics-dev" target="_new"><span style={{ color: 'black' }}><b className="fa-solid fa-download fa-lg"></b></span></a></li>
                          <li>Copilot Gen-AI</li>
                          <li>Robotics CLI <a href="https://www.npmjs.com/package/robotics" target="_new"><span style={{ color: 'black' }}><b className="fa-solid fa-download fa-lg"></b></span></a></li>
                          <li>NodeJS P2P RDK <a href="https://www.npmjs.com/package/robotics-dev" target="_new"><span style={{ color: 'black' }}><b className="fa-solid fa-download fa-lg"></b></span></a></li>
                          <li>Python P2P RDK <a href="https://pypi.org/project/robotics-dev" target="_new"><span style={{ color: 'black' }}><b className="fa-solid fa-download fa-lg"></b></span></a></li>
                          <li>REST APIs</li>
                        </ul>
                      </p>
                  </div>
              </div>
          </div>
      </section>

      <section className="hardware-support">
          <div className="section-content">
            <div className="setup-steps">
              <center><img className="d-block" src="ros.png" alt="ROS" width="300px"/></center><br/>
              <h3><span style={{ color: 'black' }}><center>Robotics.dev works out-of-the-box with any ROS2 robotics hardware.
              <br />If you need a DIY ROS2 Motor Controller, we natively support the following GPIO boards:</center></span></h3>
            </div>
              <div className="boards">
                  <div className="board">
                      <i className="fab fa-raspberry-pi"></i>
                      <h3>Raspberry Pi</h3>
                      <p>RPi Zero, RPi3, RPi4, RPi5</p>
                  </div>
                  <div className="board">
                      <i className="fas fa-microchip"></i>
                      <h3>Radxa X4 (x86)</h3>
                  </div>
                  <div className="board">
                      <i className="fas fa-microchip"></i>
                      <h3>LattePanda 3 Delta (x86)</h3>
                  </div>
                  <div className="board">
                      <i className="fas fa-desktop"></i>
                      <i className="fas fa-microchip"></i>
                      <h3>Intel NUC</h3>
                      <p>with Arduino/ESP32</p>
                  </div>
              </div>
          </div>
      </section>

      <section className="code-sample">
          <div className="section-content">
            <div className="setup-steps">
              <h3><center>Sneak Peak!</center></h3>
            </div>

              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src="robots.png" alt="Fleet management"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="teleop.png" alt="Web teleop"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="vscode.png" alt="VS Code Co-Pilot"/>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
          </div>
      </section>

      <section className="code-sample">
          <h2><center>How Does It Work?</center></h2>
          <div className="code-columns">
              <div className="setup-steps">
                  <h3><i className="fas fa-robot"></i><br/>Getting Started with Robot</h3>
                  <p>Install Robotics.dev CLI (command line interface) on robot. Learn more about our <a href="https://www.npmjs.com/package/robotics" target="_new">Robotics NPM package</a>.</p>
                  <div className="command">npm install -g robotics</div>

                  <p>Get Robot ID and add it to your list of robots in Robotics.dev</p>
                  <div className="command">robotics id</div>

                  <p>Add your developer API token to your robot</p>
                  <div className="command">robotics set --token=1234...6789</div>

                  <p>Start Communications (defaults to robotics.dev)</p>
                  <div className="command">robotics connect</div>

                  <p>Start Motors (defaults to Raspberry Pi)</p>
                  <div className="command">robotics start motors</div>

                  <p>Start 2D Camera (defaults to device /dev/video0)</p>
                  <div className="command">robotics start camera</div>

                  <p>Returns help on all commands and parameters</p>
                  <div className="command">robotics help</div>
              </div>
              <div className="setup-steps">
                  <h3><i className="fas fa-code"></i><br/>Getting Started with RDK (Robotics Developer Kit)</h3>
                  <p>Install Robotics.dev Node.JS or Python RDK on development machine (Linux, Windows, Mac). </p>

                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-nodejs-tab" data-bs-toggle="pill" data-bs-target="#pills-nodejs" type="button" role="tab" aria-controls="pills-nodejs" aria-selected="true"><i class="fa-brands fa-node-js"></i> Node.JS</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-python-tab" data-bs-toggle="pill" data-bs-target="#pills-python" type="button" role="tab" aria-controls="pills-python" aria-selected="false"><i class="fa-brands fa-python"></i> Python</button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-nodejs" role="tabpanel" aria-labelledby="pills-nodejs-tab" tabindex="0">
                    <p>Learn more about our <a href="https://www.npmjs.com/package/robotics-dev" target="_new">Robotics-dev NPM NodeJS RDK</a>.</p>
                    <div className="command">npm install robotics-dev</div>

                    <div className="code-container">
                    <CopyBlock
                      text={`//import robotics from 'robotics-dev';
const robotics = require('robotics-dev');

// Define ROS twist movement commands
const moveForward = {
  linear: {x: 0.2, y: 0.0, z: 0.0},
  angular: {x: 0.0, y: 0.0, z: 0.0}
};
const turnLeft = {
  linear: {x: 0.0, y: 0.0, z: 0.0},
  angular: {x: 0.0, y: 0.0, z: 0.2}
};
const turnRight = {
  linear: {x: 0.0, y: 0.0, z: 0.0},
  angular: {x: 0.0, y: 0.0, z: -0.2}
};
const stop = {
  linear: {x: 0.0, y: 0.0, z: 0.0},
  angular: {x: 0.0, y: 0.0, z: 0.0}
};

var latestImage;
var oneTime = true;
const robotId = 'ENTER ROBOTICS.DEV ROBOT ID HERE';
const apiToken = 'ENTER ROBOTICS.DEV DEVELOPER API TOKEN HERE';

// Connect RDK to robot via P2P and start listening for ROS messages
robotics.connect({robot: robotId, token: apiToken}, (ros) => {
  console.log('Received p2p data:', ros);
  // {
  //   robotId: '3bbb40c5-xxxx-yyyy-zzzz-58a12bc6893e',
  //   topic: '/camera2d',
  //   data: {
  //     data: xyz
  //   }
  // }
  if(ros.topic === '/camera2d'){
    console.log("Base64 image: ", ros.data.data);
    latestImage = ros.data.data
  }

  if(oneTime){
    oneTime = false;
    robotics.speak(robotId, 'beep boop')

    console.log('Moving robot forward at 20% speed...');
    robotics.twist(robotId, moveForward);

    // Stop after 5 seconds
    setTimeout(() => {
        console.log('Stopping robot...');
        robotics.twist(robotId, stop);
    }, 5000);
  }

});
                    `}
                  language={"javascript"}
                  showLineNumbers={false}
                  wrapLongLines
                  theme={dracula}
                />


                </div>
                  </div>
                  <div class="tab-pane fade" id="pills-python" role="tabpanel" aria-labelledby="pills-python-tab" tabindex="0">
                  <p>Learn more about our <a href="https://pypi.org/project/robotics-dev/" target="_new">Robotics-dev PIP Python RDK</a>.</p>
                    <div className="command">pip install robotics-dev</div>

                    <div className="code-container">
                    <CopyBlock
                      text={`
import asyncio
import signal
import base64
import sys
from pathlib import Path

# Add the src directory to Python path for local testing
src_path = Path(__file__).parent.parent / 'src'
sys.path.append(str(src_path))

# Import for local testing
from robotics_dev.robotics import robotics
# For production, use:
# from robotics_dev import robotics

# Create twist message for moving forward at 20% speed
forward_twist = {
    "linear": {
        "x": 0.2,  # 20% forward velocity
        "y": 0.0,
        "z": 0.0
    },
    "angular": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
    }
}

# Create stop twist message
stop_twist = {
    "linear": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
    },
    "angular": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
    }
}

one_time = True
robot_id = 'ENTER ROBOTICS.DEV ROBOT ID HERE'
api_token = 'ENTER ROBOTICS.DEV DEVELOPER API TOKEN HERE'

async def handle_message(ros_message):
    global one_time
    # ros_message is already parsed by the RDK
    print('Received p2p data:', ros_message)

    if one_time:
        one_time = False
        await robotics.speak(robot_id, 'this is a test')

        print('Moving robot forward at 20% speed...')
        await robotics.twist(robot_id, forward_twist)

        # Stop after 5 seconds
        await asyncio.sleep(5)
        print('Stopping robot...')
        await robotics.twist(robot_id, stop_twist)

async def main():
    # Set up signal handlers for graceful shutdown
    loop = asyncio.get_running_loop()
    for sig in (signal.SIGINT, signal.SIGTERM):
        loop.add_signal_handler(sig, lambda: asyncio.create_task(cleanup()))

    try:
        # Connect to robotics.dev
        await robotics.connect({
            'server': 'wss://robotics.dev',
            'robot': robot_id,
            'token': api_token
        }, handle_message)

        # Keep running until interrupted
        while True:
            await asyncio.sleep(1)

    except asyncio.CancelledError:
        print("Shutdown requested...")
    except Exception as e:
        print(f"Error: {e}")

async def cleanup():
    print("Disconnecting...")
    await robotics.disconnect()
    # Stop the event loop
    loop = asyncio.get_running_loop()
    loop.stop()

if __name__ == "__main__":
    asyncio.run(main())
                    `}
                  language={"javascript"}
                  showLineNumbers={false}
                  wrapLongLines
                  theme={dracula}
                />


                </div>

                  </div>
                </div>



              </div>
          </div>
      </section>

      <footer>
          <p>&copy; 2025. All rights reserved.</p>
          <p><a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms & Conditions</a></p>
      </footer>

    </div>
  )
}

export default Home;
